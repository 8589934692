
.nav-item,
.navbar {
  z-index: 100;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
/* color-picker style  */

  #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

.react-datepicker {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
}

.react-datepicker {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
}

.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0px;
}

.react-datepicker__triangle {
 display: none;
}

.react-datepicker__header {
  background-color: #FFFFFF;
  border-bottom: 0px;
}

.react-datepicker__day-name {
  border-bottom: 1px solid #000;
  margin: 0px;
  line-height: 1.5rem;
  width: 1.5rem;
}

.react-datepicker__week {
  margin-bottom: 0.1rem;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  overflow: hidden;
}

.react-datepicker__day-name {
  border-bottom: 1px solid #000;
  margin: 0px;
}

.react-datepicker__day {
  margin: 0px;
  line-height: 1.5rem;
  width: 1.5rem;
  color: #CCCCCC;
}

.react-datepicker__day--in-range {
  background-color: #C7C7FF;
  color: #000;
  border-radius: 0px !important;
}

.react-datepicker__day:hover {
  background-color: #C7C7FF;
  border-radius: 50%;
  color: #FFFFFF;
}

.react-datepicker__day--in-selecting-range {
  background-color: #C7C7FF !important;
  color: #FFFFFF;
  border-radius: 0px;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  background: radial-gradient(circle, #9191F4 0%, #9191F4 70%, #C7C7FF 70%, #C7C7FF 100%);
  color: #FFFFFF;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}

.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--in-range) {
  background: radial-gradient(circle, #DBDBDB 0%, #DBDBDB 70%, #FFFFFF 70%, #FFFFFF 100%);
  color: #000;
  border-top-right-radius: 50% ;
  border-bottom-right-radius: 50% ;
  border-top-left-radius: 50% ;
  border-bottom-left-radius: 50% ;
}


.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  background: radial-gradient(circle, #9191F4 0%, #9191F4 70%, #C7C7FF 70%, #C7C7FF 100%);
  color: #FFFFFF;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.react-datepicker__month-text {
  text-transform: capitalize;
}

.react-datepicker__month-text--keyboard-selected {
  color: #000;
  background: #DBDBDB;
}

.react-datepicker__day--selected {
  color: #FFFFFF !important;
  background: #9191F4;
  border-radius: 50%;
}
.react-datepicker__month-text--in-range {
  color: #FFFFFF !important;
  background: #9191F4;
}

.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text:hover {
  background: #C7C7FF !important;
  color: #FFFFFF !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  background: radial-gradient(circle, #9191F4 0%, #9191F4 70%, #C7C7FF 70%, #C7C7FF 100%);
  color: #FFFFFF !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.react-datepicker__day--range-start:hover {
  background: #C7C7FF;
  color: #FFFFFF;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.react-datepicker__day--range-end:hover {
  background: #C7C7FF;
  color: #FFFFFF;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__navigation-icon::before {
  border-color: #FFFFFF;
  top: 7.5px;
  border-width: 2px 2px 0 0;
}

.react-datepicker__navigation-icon--previous::before{
  right: -4px;
}

.react-datepicker__navigation-icon--next::before {
  left: -4px;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  margin-top: 0.5rem;
  border-color: #FFFFFF;
  background: radial-gradient(circle, #9191F4 0%, #9191F4 50%, #FFFFFF 50%, #FFFFFF 100%);
}
.react-datepicker__navigation--next:hover *::before,
.react-datepicker__navigation--previous:hover *::before {
  border-color: #FFFFFF;
}

.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--previous:hover {
  background: radial-gradient(circle, #C7C7FF 0%, #C7C7FF 50%, #FFFFFF 50%, #FFFFFF 100%);
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    overflow: hidden;
}


.react-datepicker {
  font-size: 12px;
  padding: 0.5rem;
}  

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  background: rgb(0, 0, 0);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

