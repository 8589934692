/*@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;1,400;1,500&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,400;0,500;1,400;1,500&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800&display=swap');*/

body {
    margin: 0;
    padding:0;
    /*font-family: 'Mulish', sans-serif;*/
    font-family: 'Inter', sans-serif; 
    /*font-family: 'Gothic A1', sans-serif;*/
    /*font-family: "Century Gothic", sans-serif;*/
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display:none
    
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.no-scrollbar-x::-webkit-scrollbar:horizontal {
  display:none
}

.overlay-scrollbar::-webkit-scrollbar {
  position: absolute;
  background: #000;
  right: -300px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}

.thin-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.perfect-scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

